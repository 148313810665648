import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  alarmCnt:  0,
  alarmList: [],
  isMobile:  false,
  cat_id:  '',
  cat_id2: '',
  cat_id3: '',
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'updateIsMobile':
      return {
        ...state,
        isMobile: action.isMobile
      };
    case 'updateCategory':
      return {
        ...state,
        cat_id: action.cat_id
      };
    case 'updateCategory2':
      return {
        ...state,
        cat_id2: action.cat_id2
      };
    case 'updateCategory3':
      return {
        ...state,
        cat_id3: action.cat_id3
      };
    case 'updateAlarm':
      return {
        ...state,
        alarmCnt:  action.alarmCnt,
        alarmList: action.alarmList ? action.alarmList : state.alarmList
      };
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
